import React, { useState } from 'react';

// reactstrap components
import { Container } from 'reactstrap';
import { withRouter, Link, useHistory, NavLink } from 'react-router-dom';
import { isAuthenticated, logOut } from 'common';
import Cookies from 'js-cookie';
const isManager = parseFloat(Cookies.get(process.env.REACT_APP_COOKIE_KEY_ISMANAGER));

export default withRouter(function Header({
	isDisplayHeader = true,
	stats = false,
	title = '',
	history,
	isHistoryBack = false,
	component = false,
	rightButton,
}) {
	const [isOpenMenuManager, setIsOpenMenuManager] = useState(false);

	if (!isDisplayHeader && !component) return null;

	const redirectLogin = () => {
		if (!isAuthenticated()) {
			window.location.href = '/login';
		}
	};

	return (
		<div className="header pb-3 pt-3" style={{ backgroundColor: `${isManager ? '#4692F4' : '#f0f0f0c4'}`, cursor: 'pointer' }}>
			<Container fluid>
				<div className="header-body text-center header_page_kit">
					<div className="clearfix">
						{
							isManager ?
								(
									<>
										{
											isHistoryBack ?
												(
													<img
														className="float-left"
														src={require('assets/img/icons/IC_Back_white.png')}
														width="30"
														onClick={() => history.goBack()}
														alt="Go Back"
													/>
												)
												:
												(
													<img
														className="float-left"
														src={require(`assets/img/icons/${!isOpenMenuManager ? 'open-menu.png' : 'close-menu.png'}`)}
														width="30"
														alt="Go Back"
														id={`manager-menu-img ${!isOpenMenuManager ? 'open' : 'close'}`}
														onClick={() => setIsOpenMenuManager(!isOpenMenuManager)}
													/>
												)
										}

										<h6 className="h2 d-inline-block mb-0 header_title">
											{title}
										</h6>

										{!!rightButton && <img
											className="float-right"
											src={rightButton.icon}
											width="30"
											alt="Go Back"
											onClick={rightButton.onClick}
										/>}
									</>
								)
								:
								(
									<>
										<img
											className="float-left"
											src={require('assets/img/icons/IC_Back.png')}
											width="30"
											onClick={() => history.goBack()}
											alt="Go Back"
										/>
										<h6 className="h2 text-dart d-inline-block mb-0">
											{title}
										</h6>
									</>
								)
						}
					</div>
				</div>
				{isOpenMenuManager ?
					<div id="manager-menu">
						<ul>
							<li><NavLink to="/overview" activeClassName="active" ><span>Overview</span></NavLink></li>
							<li><NavLink to="/summary-report" activeClassName="active" ><span>Summary Report</span></NavLink></li>
							<li><NavLink to="/employee-salaries" activeClassName="active" ><span>Employee Salaries</span></NavLink></li>
							<li><NavLink to="/marketing-report" activeClassName="active" ><span>Marketing Report</span></NavLink></li>
							<li><NavLink to="/appointments" activeClassName="active"><span>Appointments</span></NavLink></li>
							<li><NavLink to="/staff-list" activeClassName="active"><span>Staff List</span></NavLink></li>
							<li><NavLink to="/customer-report" activeClassName="active"><span>Customer Report</span></NavLink></li>
							<li><NavLink to="/images-review" activeClassName="active"><span>Images</span></NavLink></li>
							<li><NavLink to="/salon-information" activeClassName="active"><span>Salon’s Information</span></NavLink></li>
							<li><NavLink exact to="/" onClick={() => logOut(redirectLogin)}><span>Logout</span></NavLink></li>
						</ul>
					</div>
					: ''
				}
			</Container>
		</div>
	);
})
