import { get } from 'lodash';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import prevPageIcon from './icons/prev-page.svg';
import nextPageIcon from './icons/next-page.svg';
import InputSearch from './InputSearch';
import clsx from 'utils/clsx';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import customerSelectors from 'store/customer/selectors';

const CustomerTable = ({
  rowKey = '',
  dataSource = [],
  columns = [],
  total: TotalElements = 0,
  itemPerPage = 0, currentPage = 0,
  onPageChange = () => undefined,
  onFilter,
  onSearch,
  group,
}) => {
  const loadingTable = useAppSelector(customerSelectors.loadingTable);
  const _history = useHistory();

  const totalPage = useMemo(() => {
    const x = TotalElements / itemPerPage;
    return Math.floor(x) + ((x - Math.floor(x)) > 0 ? 1 : 0);
  }, [TotalElements, itemPerPage]);

  return (
    <>
      {onSearch && <div className="ph">
        <InputSearch onSearch={onSearch} onFilter={onFilter} group={group} />
      </div>}
      <table className="customer-table">
        {loadingTable && <div className="overlay-loader">
          <div className="loader">
            <div className="spinner" />
          </div>
        </div>}
        <thead>
          <tr>
            {columns.map(col => (
              <th key={col.key} className={clsx(col.align ? 'text-' + col.align : '', col.className)}>{col.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((record, i) => {
            const id = get(record, rowKey, i);

            return <tr key={id}>
              {columns.map(({ ellipsis, key, className, dataIndex, align }) => {
                if (ellipsis) return (
                  <td key={key} className={clsx('ellipsis', align ? 'text-' + align : '', className)} onClick={() => _history.push(`/customer/${id}`)}>
                    <div className={'ellipsis-text'}>{get(record, dataIndex) || ''}</div>
                  </td>
                )
                return (
                  <td key={key} className={clsx(align ? 'text-' + align : '', className)}>
                    {get(record, dataIndex) || ''}
                  </td>
                )
              })}
            </tr>
          })}
        </tbody>
        {!!totalPage &&
          <tfoot>
            <tr><td colSpan={columns.length}>
              <div className="pagination">
                <div className="pagination-container">
                  <button className='pagination-btn' disabled={(currentPage - 1) < 0} onClick={() => onPageChange(currentPage - 1)}>
                    <img src={prevPageIcon} alt="prevPageIcon" className='action-icon prev-icon' />
                  </button>
                  <div className='pagination-text'><span>{currentPage}/{totalPage}</span></div>
                  <button className='pagination-btn' disabled={(currentPage + 1) > totalPage} onClick={() => onPageChange(currentPage + 1)}>
                    <img src={nextPageIcon} alt="nextPageIcon" className='action-icon next-icon' />
                  </button>
                </div>
              </div>
            </td></tr>
          </tfoot>}
      </table>
    </>
  )
};

export default CustomerTable;
