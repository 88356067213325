import React, { useState } from 'react'
import playIcon from '../icons/play.svg';
import delete_icon from '../icons/delete.svg';
import publish_icon from '../icons/global.svg';
import cancel_icon from '../icons/cancel.svg';
import complete_icon from '../icons/complete.svg';
import upload_icon from '../icons/upload.svg';
import select_box_icon from '../icons/select-box.svg';
import select_box_checked_icon from '../icons/select-box-checked.svg';
import { modalViewGalleryRef } from '../ModalViewGallery';

const StaffMediaItem = ({ onUpload = () => undefined, data, pending = false, onPublish = () => undefined, onDelete = () => undefined }) => {
  const [mode, setMode] = useState(null);
  const [selected, setSelected] = useState([]);
  const isSelected = (mode === 'DELETE' || mode === 'PUBLISH');

  const handleChooseFile = (id) => {
    const temp = [...selected];
    const idx = selected.indexOf(id);
    if (idx !== -1) {
      temp.splice(idx, 1);
    } else {
      temp.push(id);
    }
    setSelected(temp);
  }

  const onClickGallery = (file) => () => {
    if (!mode) {
      if (modalViewGalleryRef.current) modalViewGalleryRef.current.open(file);
      return;
    }
    handleChooseFile(file.id);
  };

  const handleCancel = () => {
    setMode(null);

    setSelected([]);
  };
  const handleDelete = () => {
    setMode(null);
    onDelete(selected);
    setSelected([]);
  };

  const handlePublish = () => {
    setMode(null);
    onPublish(selected);
    setSelected([]);
  };

  const ButtonActions = () => {
    if (mode === 'DELETE') {
      return (
        <>
          <button onClick={handleCancel} className="btn btn-cancel">
            <img src={cancel_icon} alt="cancel_icon" className='btn-icon' />
          </button>
          <button onClick={handleDelete} className="btn btn-delete">
            <img src={complete_icon} alt="complete_icon" className='btn-icon' />
          </button>
        </>
      )
    }
    if (mode === 'PUBLISH') {
      return (
        <>
          <button onClick={handleCancel} className="btn btn-cancel">
            <img src={cancel_icon} alt="cancel_icon" className='btn-icon' />
          </button>
          <button onClick={handlePublish} className="btn btn-publish">
            <img src={complete_icon} alt="complete_icon" className='btn-icon' />
          </button>
        </>
      )
    }

    if (pending)
      return (<>
        <button onClick={() => setMode('DELETE')} className="btn btn-delete">
          <img src={delete_icon} alt="delete_icon" className='btn-icon' />
        </button>
        <button onClick={() => setMode('PUBLISH')} className="btn btn-publish">
          <img src={publish_icon} alt="publish_icon" className='btn-icon' />
        </button>
      </>)

    return (<>
      <button onClick={() => setMode('DELETE')} className="btn btn-delete">
        <img src={delete_icon} alt="delete_icon" className='btn-icon' />
      </button>
      <button onClick={() => onUpload(data.id)} className="btn btn-upload">
        <img src={upload_icon} alt="upload_icon" className='btn-icon' />
      </button>
    </>)
  }
  if (!data.files?.length) {
    return (
      <div className='staff-media-item-container' style={{ marginTop: 0 }}>
        <div className="staff-media-item-header" style={{ transform: 'unset' }}>
          <div className="staff-avatar-container">
            <img className='image-avatar' src={data.avatar} alt={'avatar' + data.name} />
            <div className="staff-name">{data.name || 'A'}</div>
          </div>
          <div className="staff-media-item-actions">
            <ButtonActions />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='staff-media-item-container'>
      <div className="staff-media-item-header">
        <div className="staff-avatar-container">
          <img className='image-avatar' src={data.avatar} alt={'avatar' + data.name} />
          <div className="staff-name">{data.name || 'A'}</div>
        </div>
        <div className="staff-media-item-actions">
          <ButtonActions />
        </div>
      </div>
      <div className="staff-images-container">
        {data.files.map(file => (
          <button onClick={onClickGallery(file)} type='button' key={file.id} className='media-item'>
            {file.type === 'video' ?
              <video src={file.source} muted className='media-video' /> :
              <img src={file.source} alt={file.source} className='media-image' />}
            {file.type === 'video' && <div className='video-overlay'><img src={playIcon} alt="playIcon" className='play-icon' /></div>}
            {isSelected && <div className='video-overlay'></div>}
            {isSelected && <img src={selected.includes(file.id) ? select_box_checked_icon : select_box_icon} alt="select_box_icon" className='select_box' />}
          </button>
        ))}
      </div>
    </div>
  )
}

export default StaffMediaItem;
