import Login from 'views/Login.jsx';
import Index from 'views/Index.jsx';
import TicketDetail from 'views/TicketDetail';
import Overview from 'views/Overview';
import SummaryReport from 'views/SummaryReport';
import Appointments from 'views/Appointments';
import DetailAppointment from 'views/DetailAppointment';
import ReviewInformation from 'views/ReviewInformation';
import ReassignTickets from 'views/ReassignTickets';
import StaffList from 'views/StaffList';
import TicketByStatff from 'views/TicketByStatff';
import EmployeeSalaries from 'views/employee-salaries/index';
import MarketingReport from 'views/marketing-report';
import ReviewComment from 'views/marketing-report/ReviewComment';
import Coupon from 'views/marketing-report/Coupon';
import TicketByStaffV2 from 'views/TicketByStaffV2';
import TicketDetailByStaff from 'views/TicketDetailByStaff';
import CheckAvailableStaff from 'views/new-appointment/check-available';
import DetailAppointmentV2, { DetailAppointmentForStaff } from 'views/detail-appointment';
import OwnerCalendar from 'views/calendar/OwnerCalendar';
import CustomerReport from 'views/customer-report';
import CustomerGroupPage from 'views/customer-report/CustomerGroupPage';
import CustomerDetailPage from 'views/customer-report/CustomerDetail';
import CustomerEditInfoPage from 'views/customer-report/CustomerEditInfoPage';
import ImageReviewPage from 'views/landing-page-setting/image-reviews';
import SalonInformationPage from 'views/landing-page-setting/SalonInformation';

var routes = [
	// staff
	{
		path: '/',
		name: 'Dashboard',
		component: Index,
		layout: 'admin',
		// stats: true,
		isDisplayHeader: false,
		isDisplayNav: false,
		isManager: false
	},
	{
		path: '/ticketdetail',
		name: 'Ticket Details',
		component: TicketDetail,
		layout: 'admin',
		isDisplayNav: false,
		isManager: false,
		isDisplayHeader: false,
	},
	// manager
	{
		path: '/overview',
		name: 'Overview',
		component: Overview,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/summary-report',
		name: 'Summary Report',
		component: SummaryReport,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/customer-report',
		name: 'Customer Report',
		component: CustomerReport,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/images-review',
		name: 'Image Reviews',
		component: ImageReviewPage,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/salon-information',
		name: 'Salon’s Information',
		component: SalonInformationPage,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/customer-group/:group',
		name: 'Customer Group',
		component: CustomerGroupPage,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/customer/:id',
		name: 'Customer Detail',
		component: CustomerDetailPage,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/customer/edit/:id',
		name: 'Customer Edit',
		component: CustomerEditInfoPage,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/employee-salaries',
		name: 'Employee Salaries',
		component: EmployeeSalaries,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/marketing-report',
		name: 'Marketing Report',
		component: MarketingReport,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/marketing-report/reviews-comments',
		name: 'Review & Comment',
		component: ReviewComment,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/marketing-report/coupon',
		name: 'Coupon',
		component: Coupon,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/appointments',
		name: 'Appointments',
		component: Appointments,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/appointment/:id',
		name: 'Appointments Detail',
		component: DetailAppointmentV2,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isHistoryBack: true,
		isDisplayHeader: false,
	},
	{
		path: '/appointment-staff/:id',
		name: 'Appointments Detail',
		component: DetailAppointmentForStaff,
		layout: 'admin',
		isDisplayNav: false,
		isManager: false,
		isHistoryBack: true,
		isDisplayHeader: false,
	},
	{
		path: '/reviewInformation',
		name: 'Review Information',
		component: ReviewInformation,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true
		// isDisplayHeader: false,
	},
	{
		path: '/reassignTickets',
		name: 'Reassign Tickets',
		component: ReassignTickets,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true
		// isDisplayHeader: false,
	},
	{
		path: '/staff-list',
		name: 'Staff List',
		component: StaffList,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/ticket-by-staff/:id/:name',
		component: TicketByStatff,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isHistoryBack: true
	},
	{
		path: '/ticket-by-staff/:id',
		component: TicketByStaffV2,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isHistoryBack: true,
		isDisplayHeader: false,
	},
	{
		path: '/ticket-by-staff/:staff_id/ticket/:ticket_id',
		name: 'Ticket Details',
		component: TicketDetailByStaff,
		layout: 'admin',
		isDisplayNav: false,
		isManager: true,
		isDisplayHeader: false,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		layout: 'auth',
	},
	{
		path: '/check-available-booking',
		name: 'Available Booking',
		component: CheckAvailableStaff,
		layout: 'admin',
		isDisplayHeader: false,
		isDisplayNav: false,
		isManager: true,
	},
	{
		path: '/calendar',
		name: 'Calendar Booking',
		component: OwnerCalendar,
		layout: 'admin',
		isDisplayHeader: false,
		isDisplayNav: false,
		isManager: true,
	}
];
export default routes;
