import { createAsyncAction } from 'store/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
const getAllCustomers = createAsyncAction(PREFIX_ACTIONS + 'getAllCustomers');
const getSummaryCustomers = createAsyncAction(PREFIX_ACTIONS + 'getSummaryCustomers');
const getCustomersByGroup = createAsyncAction(PREFIX_ACTIONS + 'getCustomersByGroup');
const getSummaryCustomersByGroup = createAsyncAction(PREFIX_ACTIONS + 'getSummaryCustomersByGroup');
const getCustomerDetail = createAsyncAction(PREFIX_ACTIONS + 'getCustomerDetail');
const getCustomerTransactions = createAsyncAction(PREFIX_ACTIONS + 'getCustomerTransactions');

const customerActions = {
  getAllCustomers,
  getSummaryCustomers,
  getCustomersByGroup,
  getCustomerDetail,
  getCustomerTransactions,
  getSummaryCustomersByGroup,
};

export default customerActions;
