import React from 'react';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	HashRouter,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import { isAuthenticated } from 'common';
import 'react-spring-bottom-sheet/dist/style.css';
// Library
import 'assets/css/slick.min.css'
import 'assets/css/slick-theme.min.css'
import 'assets/scss/argon-dashboard-react.scss';
import 'assets/css/font-awesome.min.css'

// Layout display
import AdminLayout from 'layouts/Admin.jsx';
import AuthLayout from 'layouts/Auth.jsx';
import { ErrorBoundary } from 'react-error-boundary';
import store from 'store';
import { Provider } from 'react-redux';
import ToastContainer, { toastRef } from 'components/Toast';
import OnlineBooking from 'views/new-appointment/booking/OnlineBooking';
import StaffInformation from 'views/landing-page-setting/StaffInformation';
function ErrorFallback({ error }) {
	return (
		<div color="danger">
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
		</div>
	);
}

function App() {
	return (
		<Provider store={store}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<Router>
					<Switch>
						<Route
							path="/login"
							render={(props) => <AuthLayout {...props} />}
						/>
						<Route path='/:id/booking' render={() => <OnlineBooking />} />
						<Route path='/staff-information' render={() => <StaffInformation />} />
						<Route
							path="/"
							render={(props) =>
								isAuthenticated() ? (
									<AdminLayout {...props} />
								) : (
									<Redirect
										to={{
											pathname: '/login',
											state: { from: props.location },
										}}
									/>
								)
							}
						/>
					</Switch>
				</Router>
				<ToastContainer ref={toastRef} />
			</ErrorBoundary>
		</Provider>
	)
}

ReactDOM.render(App(),
	document.getElementById('root')
);
