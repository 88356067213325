import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react'

const RangeSlider = ({ min, max, step = 1, onChange, format = () => undefined, maxFieldName = 'max', minFieldName = 'min', defaultMax, defaultMin = 0 }) => {
  const [minVal, setMinVal] = React.useState(defaultMin);
  const [maxVal, setMaxVal] = useState(defaultMax ?? step);
  const minValRef = React.useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );
  const onChangeDebounce = useCallback(debounce((min, max) => onChange && onChange({ [minFieldName]: min, [maxFieldName]: max }), 200), [onChange, maxFieldName, minFieldName]);
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  return (
    <div className='slider_ui_kit'>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - step);
          setMinVal(value);
          minValRef.current = value;
          onChangeDebounce(value, maxVal);
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + step);
          setMaxVal(value);
          maxValRef.current = value;
          onChangeDebounce(minVal, value);
        }}
        className="thumb thumb--right"
      />
      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{format(minVal)}</div>
        <div className="slider__right-value">{format(maxVal)}</div>
      </div>
    </div>
  )
}

export default RangeSlider
