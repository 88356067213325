import React from 'react'

const SalonContact = ({ form }) => {
  return (
    <>
      <input hidden name='shopId' ref={form.register} type="text" placeholder='Please enter...' />
      <input hidden name='companyName' ref={form.register} type="text" placeholder='Please enter...' />
      <div className="salon-information-input-container">
        <span className="label-input">Facebook Link</span>
        <input name='facebookUrl' ref={form.register} type="text" placeholder='Please enter...' />
      </div>
      <div className="salon-information-input-container">
        <span className="label-input">Instagram Link</span>
        <input name='instagramUrl' ref={form.register} type="text" placeholder='Please enter...' />
      </div>
      <div className="salon-information-input-container">
        <span className="label-input">Google Link</span>
        <input name='googleUrl' ref={form.register} type="text" placeholder='Please enter...' />
      </div>
      <div className="salon-information-input-container">
        <span className="label-input">Yelp Link</span>
        <input name='yelpUrl' ref={form.register} type="text" placeholder='Please enter...' />
      </div>
      <div className="salon-information-input-container">
        <span className="label-input">Street</span>
        <input name='address' ref={form.register} type="text" placeholder='Please enter...' />
      </div>
      <div className="salon-information-input-container">
        <span className="label-input">City</span>
        <input name='city' ref={form.register} type="text" placeholder='Please enter...' />
      </div>
      <div className="salon-information-input-container">
        <span className="label-input">State</span>
        <input name='state' ref={form.register} type="text" placeholder='Please enter...' />
      </div>
      <div className="salon-information-input-container">
        <span className="label-input">Zip Code</span>
        <input name='zip' ref={form.register} type="text" placeholder='Please enter...' />
      </div>
    </>
  )
}

export default SalonContact;
