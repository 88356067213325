import Page from 'components/Page';
import React, { useEffect, useState } from 'react'
import { Button, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import customerSelectors from 'store/customer/selectors';
import { customerUIActions } from 'store/customer/reducers';
import Loader from 'components/Loader';
import { useForm, Controller } from 'react-hook-form';
import { formatPhone } from 'utils/formatPhone';
import InputPhone from 'components/new-appointment/InputPhone';
import DOBInput from 'components/InputDOB';
import { toastRef } from 'components/Toast';
import { API } from 'common';
import customerActions from 'store/customer/actions';

const CustomerEditInfoPage = () => {
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();
  const state = useAppSelector(customerSelectors.detail);
  const { register, handleSubmit, setValue, control } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(customerUIActions.initDetail(id));
  }, []);

  useEffect(() => {
    if (!state.data) return;
    const { customerName = '', customerId, customerPhone = '', dob = '', email = '', address = '', city = '', zip = '' } = state.data;
    setValue('id', customerId);
    setValue('fullName', customerName);
    setValue('phone', formatPhone(customerPhone));
    setValue('dob', dob);
    setValue('email', email);
    setValue('address', address);
    setValue('city', city);
    setValue('state', state.data.state || '');
    setValue('zip', zip);
  }, [state.data]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      let res = await API.post(`customer/update`, { payload: values });
      if (res.status === 200) {
        if (toastRef.current) {
          toastRef.current.success('Updated!');
          dispatch(customerActions.getCustomerDetail.fetch(id));
        }
      }
    } catch (error) {
      if (toastRef.current) toastRef.current.show(error?.response?.data?.message || 'An error occurred, please try again!');
    } finally {
      setLoading(false);
    }
  }

  if (state.loading) return <Loader />;
  if (!state.data) return <Page title={'Not found'}><p>404 Not Found</p></Page>;


  const StickFooter = () => (
    <Row style={{ padding: '4px 15px', background: '#f0f0f0c4', paddingBottom: '8px' }}>
      <div style={{ margin: '0 15px', width: '100%', display: 'flex', gap: 8 }}>
        <Button type='submit' className="w-100" color="primary" style={{ fontSize: 14, margin: 0, padding: '0.625rem 0rem' }} >Edit</Button>
      </div>
    </Row>
  );
  return (
    <>
      {loading && <Loader />}
      <Page StickFooter={StickFooter} title={'Edit Information'} className={'customer-edit-info-page'} onSubmit={handleSubmit(onSubmit)}>
        <input name='id' ref={register} hidden />
        <InputGroup label={'Customer Name'}>
          <input name='fullName' ref={register({ required: true })} type="text" />
        </InputGroup>
        <InputGroup label={'Phone'}>
          <Controller
            control={control}
            name="phone"
            render={(field) => <InputPhone {...field} />}
          />
        </InputGroup>
        <InputGroup label={'DOB'}>
          <Controller
            control={control}
            name="dob"
            render={(field) => <DOBInput {...field} />}
          />
        </InputGroup>
        <InputGroup label={'Email'}>
          <input name='email' ref={register} type="text" placeholder='Please enter...' />
        </InputGroup>
        <InputGroup label={'Address'}>
          <textarea name='address' ref={register} placeholder='Please enter...' />
        </InputGroup>
        <InputGroup label={'City'}>
          <input name='city' ref={register} placeholder='Please enter...' />
        </InputGroup>
        <InputGroup label={'State'}>
          <input name='state' ref={register} placeholder='Please enter...' />
        </InputGroup>
        <InputGroup label={'Zip'}>
          <input name='zip' ref={register} placeholder='Please enter...' />
        </InputGroup>
      </Page>
    </>
  )
}

export default CustomerEditInfoPage;

const InputGroup = ({ children, label }) => {
  return (
    <div className='input-group-control'>
      <label>{label}</label>
      {children}
    </div>
  )
};
