import React, { useMemo } from 'react'
import eyeIcon from './icons/showhide.svg';
import phoneIcon from './icons/phone.svg';
import emailIcon from './icons/email.svg';
import birthdayIcon from './icons/birthday.svg';
import addressIcon from './icons/address.svg';
import clockIcon from './icons/clock.svg';
import { useAppSelector } from 'store/hooks';
import customerSelectors from 'store/customer/selectors';
import { formatPhone } from 'utils/formatPhone';
import { formatNumber } from 'utils/formatCurrency';

const AboutTab = () => {
  const data = useAppSelector(customerSelectors.customerDetailInfo);
  const address = useMemo(() => {
    return [data.address, data.city, data.state, data.zip].filter(o => !!o).join(', ');
  }, [data]);
  
  return (
    <div className='customer-detail-tab-content-about-tab'>
      <div className="statistic-container">
        <div className="statistic-label">Statistics</div>
        <img src={eyeIcon} alt="eyeIcon" className="show-icon" />
      </div>
      <div className="about-details">
        <div className="about-detail-item">
          <img src={phoneIcon} alt="phoneIcon" className="icon" />
          <div className="about-detail-sub-item">
            <div className="about-detail-sub-item-label">Phone:</div>
            <div className="about-detail-sub-item-value phone">{formatPhone(data.customerPhone)}</div>
          </div>
        </div>
        <div className="about-detail-item">
          <img src={emailIcon} alt="emailIcon" className="icon" />
          <div className="about-detail-sub-item">
            <div className="about-detail-sub-item-label">Email:</div>
            <div className="about-detail-sub-item-value">{data.email || '--'}</div>
          </div>
        </div>
        <div className="about-detail-item">
          <img src={birthdayIcon} alt="birthdayIcon" className="icon" />
          <div className="about-detail-sub-item">
            <div className="about-detail-sub-item-label">DOB:</div>
            <div className="about-detail-sub-item-value">{data.dob || '--'}</div>
          </div>
        </div>
        <div className="about-detail-item">
          <img src={addressIcon} alt="addressIcon" className="icon" />
          <div className="about-detail-sub-item">
            <div className="about-detail-sub-item-label">Address:</div>
            <div className="about-detail-sub-item-value">{address || '--'}</div>
          </div>
        </div>
        <div className="about-detail-item">
          <img src={clockIcon} alt="clockIcon" className="icon" />
          <div className="about-details" style={{ gap: 4 }}>
            <div className="about-detail-sub-item">
              <div className="about-detail-sub-item-label">Create Date:</div>
              <div className="about-detail-sub-item-value">{data.createdDate || '--'}</div>
            </div>
            <div className="about-detail-sub-item">
              <div className="about-detail-sub-item-label">Last Visit Date:</div>
              <div className="about-detail-sub-item-value">{data.lastTimeVisit || '--'}</div>
            </div>
            <div className="about-detail-sub-item">
              <div className="about-detail-sub-item-label">Visited:</div>
              <div className="about-detail-sub-item-value">{formatNumber(data.numVisit || 0)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutTab;
