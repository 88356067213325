
const getCurrentState = (state) => state['shop'];

const shopSelectors = {
  staff: (state) => getCurrentState(state).staffs,
  categories: (state) => getCurrentState(state).categories,
  allServices: (state) => getCurrentState(state).allServices,
  scheduleBooking: (state) => getCurrentState(state).scheduleBooking,
  categoriesAll: state => {
    const currentState = getCurrentState(state);
    const categories = currentState.categories;
    const allServices = currentState.allServices;
    if (!categories?.length) return [];
    return ([
      { id: 'all', name: 'All', services: [...allServices] },
    ].concat(categories))
  },
  shopLoading: (state) => getCurrentState(state).shopLoading,
  shopInfo: (state) => getCurrentState(state).shopInfo,
  allBanner: (state) => getCurrentState(state).allBanner,
};

export default shopSelectors;
