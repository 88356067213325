import React from 'react'
import HeaderNav from './Headers/HeaderNav'
import Header from 'components/Headers/Header';
const Page = ({ children, title, className, border, Sticky, menu, StickFooter, noneHeader, containerClass, rightButton, onSubmit }) => {
  if (onSubmit) {
    return <form onSubmit={onSubmit} className={`page-container ${containerClass || ''}`}>
      {!noneHeader ? <>{menu ? <Header component title={title} rightButton={rightButton} /> : <HeaderNav title={title} />}</> : null}
      {border && <div style={{ borderBottom: '1px solid #E5E5E5' }}></div>}
      {Sticky ? <Sticky /> : null}
      <div className='scroll-container'>
        <div className={`scroll-div ${className}`}>
          {children}
        </div>
      </div>
      {StickFooter ? <StickFooter /> : null}
    </form>
  }
  return (
    <div className={`page-container ${containerClass || ''}`}>
      {!noneHeader ? <>{menu ? <Header component title={title} rightButton={rightButton} /> : <HeaderNav title={title} />}</> : null}
      {border && <div style={{ borderBottom: '1px solid #E5E5E5' }}></div>}
      {Sticky ? <Sticky /> : null}
      <div className='scroll-container'>
        <div className={`scroll-div ${className}`}>
          {children}
        </div>
      </div>
      {StickFooter ? <StickFooter /> : null}
    </div>
  )
}

export default Page;
