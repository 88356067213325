import { createAsyncAction } from 'store/actionConfigs';
import { PREFIX_ACTIONS } from './constants';

const getPendingGallery = createAsyncAction(PREFIX_ACTIONS + 'getPendingGallery');
const getApprovedGallery = createAsyncAction(PREFIX_ACTIONS + 'getApprovedGallery');
const staff = {
  getPendingGallery: createAsyncAction(PREFIX_ACTIONS + 'staff' + 'getPendingGallery'),
  getApprovedGallery: createAsyncAction(PREFIX_ACTIONS + 'staff' + 'getApprovedGallery'),
}

const galleryActions = {
  getPendingGallery,
  getApprovedGallery,
  staff,
};

export default galleryActions;
