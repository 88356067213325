import React from 'react'
import Slider from "react-slick";
import UploadContainer from './UploadContainer';
import { set } from 'lodash';
import { uuid } from 'uuidv4';
var settings = {
  className: "center",
  centerMode: true,
  infinite: false,
  centerPadding: "60px",
  slidesToShow: 1,
  speed: 500
};
const PromotionBanner = ({ data = [], setData = () => undefined }) => {
  const onAddPromotionBanner = (file) => {
    const temp = [...data];
    temp.push({ id: uuid(), src: file.src, file });
    setData(temp);
  }
  const onChangePromotionBanner = (id, file) => {
    const idx = data.findIndex(o => o.id === id);
    if (idx === -1) return;
    const temp = [...data];
    set(temp[idx], 'src', file.src);
    set(temp[idx], 'file', file);
    setData(temp);
  }

  const onDeletePromotionBanner = (id) => {
    const idx = data.findIndex(o => o.id === id);
    if (idx === -1) return;
    const temp = [...data];
    temp.splice(idx, 1);
    setData(temp);
  }
  return (
    <Slider {...settings}>
      {data.map(o => (
        <div key={o.id}>
          <UploadContainer onChange={onChangePromotionBanner} onDelete={onDeletePromotionBanner} file={o} id={o.id} />
        </div>
      ))}
      <div>
        <UploadContainer onAdd={onAddPromotionBanner} id={'add_promotion_banner_upload'} />
      </div>
    </Slider>
  )
}

export default PromotionBanner;
