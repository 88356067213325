
const getCurrentState = (state) => state['customer'];

const customerSelectors = {
  state: (state) => getCurrentState(state),
  summary: (state) => getCurrentState(state).summary,
  customers: (state) => getCurrentState(state).customers,
  totalCustomers: (state) => getCurrentState(state).totalCustomers,
  params: (state) => getCurrentState(state).params,
  loadingTable: (state) => getCurrentState(state).loadingTable,
  customersByGroup: (state) => getCurrentState(state).customersByGroup,
  detail: (state) => getCurrentState(state).detail,
  customerDetailInfo: (state) => getCurrentState(state).detail.data,
  customerTransactions: (state) => getCurrentState(state).detail.transactions,
};

export default customerSelectors;
