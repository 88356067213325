import React, { useCallback, useState } from 'react'
import searchIcon from './icons/search.svg';
import filterIcon from './icons/filter.svg';
import clsx from 'utils/clsx';
import { debounce, defaults, get } from 'lodash';
import BottomSheet, { useBottomSheetRef } from 'components/BottomSheet';
import RangeSlider from 'components/RangeSlider';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';
import { CUSTOMER_GROUP_TYPE } from './CustomerGroupPage';
import moment from 'moment';
const amountOptions = [
  { label: 'None', value: 'none' },
  { label: 'Less $500.00', value: JSON.stringify({ fromAmount: 500 }) },
  { label: '$500.00 -$1,000.00', value: JSON.stringify({ fromAmount: 500, toAmount: 1000 }) },
  { label: 'More $1,000.00', value: JSON.stringify({ fromAmount: 1000 }) },
];
const formatPoint = e => formatNumber(e) + ' point';

const pointOptions = [
  { label: 'None', value: 'none' },
  { label: 'Less 100', value: JSON.stringify({ fromPoint: 100 }) },
  { label: '101 - 200', value: JSON.stringify({ fromPoint: 101, toPoint: 200 }) },
  { label: '201 - 500', value: JSON.stringify({ fromPoint: 201, toPoint: 500 }) },
  { label: '501 - 1,000', value: JSON.stringify({ fromPoint: 501, toPoint: 1000 }) },
  { label: 'More 1,000', value: JSON.stringify({ fromPoint: 1000 }) },
];
const formatWeek = e => formatNumber(e) + ' week';
const weekOptions = [
  { label: 'None', value: 'none' },
  { label: 'Less 2 weeks', value: 'less-2-weeks' },
  { label: '2 weeks - 4 week', value: '2weeks-4week' },
  { label: 'More 5 weeks', value: 'more-5-weeks' },
];

const InputSearch = ({ onFilter, onSearch, group }) => {
  const [amountRange, setAmountRange] = useState('none');
  const [pointRange, setPointRange] = useState('none');
  const [notReturnRange, setNotReturnRange] = useState('none');
  const [returnRange, setReturnRange] = useState('none');

  const [focus, setFocus] = useState(false);
  const [text, setText] = useState('');
  const _debounce = useCallback(debounce((text) => onSearch && onSearch(text), 200), [onSearch]);
  const bsRef = useBottomSheetRef();
  const onChange = (e) => {
    const _text = e.target.value?.trim();
    setText(_text);
    _debounce(_text);
  };
  const onOpenFilter = () => {
    bsRef.current && bsRef.current.open();
  }
  
  const parseWeekValue = (value) => {
    if (value === 'none') return {};
    if (value === 'less-2-weeks') return {
      toDay: moment().subtract(2, 'week').format('YYYY-MM-DD'),
    };
    if (value === '2weeks-4week') return {
      fromDay: moment().subtract(4, 'week').format('YYYY-MM-DD'),
      toDay: moment().subtract(2, 'week').format('YYYY-MM-DD'),
    };
    if (value === 'more-5-weeks') return {
      toDay: moment().subtract(5, 'week').format('YYYY-MM-DD'),
    };
    if (typeof value === 'object') {
      const { max, min } = value;
      return ({
        fromDay: moment().subtract(max, 'week').format('YYYY-MM-DD'),
        toDay: moment().subtract(min, 'week').format('YYYY-MM-DD'),
      });
    }
    return {};
  };

  const onOK = () => {
    const amount = amountRange !== 'none' ? (typeof amountRange === 'string' ? JSON.parse(amountRange) : amountRange) : {};
    const point = pointRange !== 'none' ? (typeof pointRange === 'string' ? JSON.parse(pointRange) : pointRange) : {};
    const notReturnValue = parseWeekValue(notReturnRange);
    const returnValue = parseWeekValue(returnRange);
    const query = defaults(amount, point, notReturnValue, returnValue);
    onFilter(query);
  };

  return (
    <>
      <div className={clsx('input-search-container', { focus })}>
        <div className="input-search-selector">
          <img src={searchIcon} alt="searchIcon" className="search-icon" />
          <input value={text} onChange={onChange} placeholder='Customer name/phone' type="text" className='input-search' onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} />
        </div>
        {onFilter && <button onClick={onOpenFilter} type='button' className="filter-button">
          <img src={filterIcon} alt="filterIcon" className="icon-filter" />
        </button>}
      </div>
      <BottomSheet
        ref={bsRef}
        autoHeight
        className='filter_option_kit_container_bs customer-report-filter-bs'
        title={'Filter'}
        onOK={onOK}
      >
        <div className='filter_option_kit_container_wrap'>
          {group === CUSTOMER_GROUP_TYPE.LOYALTY && (
            <>
              <NumberSection
                label='Amount'
                min={0} max={100000}
                value={amountRange} onChange={setAmountRange}
                options={amountOptions}
                format={formatCurrency}
                minFieldName={'fromAmount'}
                maxFieldName={'toAmount'}
                step={100}
              />
              <NumberSection
                label='Point Amount'
                min={0} max={10000}
                value={pointRange} onChange={setPointRange}
                options={pointOptions}
                format={formatPoint}
                minFieldName={'fromPoint'}
                maxFieldName={'toPoint'}
                grid3
                step={100}
              />
            </>
          )}
          {group === CUSTOMER_GROUP_TYPE.AT_RISK && (
            <>
              <NumberSection
                label='Number of week not yet returned'
                min={0} max={24}
                value={notReturnRange} onChange={setNotReturnRange}
                options={weekOptions}
                format={formatWeek}
              />
            </>
          )}
          {group === CUSTOMER_GROUP_TYPE.RETURN && (
            <>
              <NumberSection
                label='Number of week returned'
                min={0} max={24}
                value={returnRange} onChange={setReturnRange}
                options={weekOptions}
                format={formatWeek}
              />
            </>
          )}
        </div>
      </BottomSheet>
    </>
  )
}

export default InputSearch;

const NumberSection = ({ min, max, step = 1, label = '', value, onChange, options, grid3 = false, format = () => undefined, maxFieldName = 'max', minFieldName = 'min' }) => {
  const getDefault = (field) => {
    const _value = get(value, field);
    if (typeof _value === 'string') return undefined;
    return _value;
  }
  return (
    <div className="filter_option_kit_items ">
      <div className="section-title">{label}</div>
      <div className={clsx("filter_option_kit_items_grid", grid3 ? 'point-amount-filter' : 'amount-filter')}>
        {options.map(o => (
          <button onClick={() => onChange(o.value)} className={clsx('option_filter_item', o.value === value && 'active')}><span>{o.label}</span></button>
        ))}
      </div>
      <div className="filter_option_kit_item_group_range">
        <RangeSlider
          min={min}
          max={max}
          onChange={onChange}
          format={format}
          step={step}
          maxFieldName={maxFieldName}
          minFieldName={minFieldName}
          defaultMax={getDefault(maxFieldName) || step}
          defaultMin={getDefault(minFieldName)}
        />
      </div>
    </div>
  )
}