
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
	Container,
	Row,
	Col,
	Card,
	CardImg,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Dropdown,
} from 'reactstrap';
import classnames from 'classnames';
import constant from 'constant';
import { API, getDataCookie } from 'common';
import SockJS from 'sockjs-client';
import { over } from "stompjs";
import { formatCurrency } from 'utils/formatCurrency';
import StarRatings from 'react-star-ratings';
import moment from 'moment';
import { sumBy } from 'lodash';
import MyDateRangePicker from 'components/DatePicker/Range';

export default function Income() {
	const [activeTabInCome, setActiveTabInCome] = useState('1');
	const [type, setType] = useState('DAY')
	const [infoIncome, setInfoIncome] = useState({});
	const toggle = (tab) => {
		if (tab == '3') {
			const dateMoment = moment();
			const start_date = dateMoment.clone().subtract(14, 'day').format('YYYY-MM-DD');
			const end_date = dateMoment.format('YYYY-MM-DD');
			const range_param = `startDate=${start_date}&endDate=${end_date}`;
			fetchRange(range_param);
		} else {
			fech_income_data(tab == '2' ? 'WEEK' : 'DAY');
		}
		if (activeTabInCome !== tab) setActiveTabInCome(tab);
		setType(constant.TYPE_INCOME[tab - 1]);
	};
	const staffId = getDataCookie(process.env.REACT_APP_COOKIE_KEY);
	const fech_income_data = async (_type) => {
		const typeData = _type || type;
		try {
			let response = await API.get(`report/getBillByStaff?type=${typeData}&staffId=${staffId}`);
			if (response.status == 200) {
				let { payload } = response.data;
				payload.totalAmount = payload.totalAmount.toFixed(2);
				setInfoIncome(payload);
				// console.log(payload);
			}
		} catch (error) {
			console.error('fech_income_data', error);
		}
	}

	useEffect(() => {
		fech_income_data();
		//setup socket
		var socket = new SockJS(constant.SOCKET_URL);
		var stompClient = null;
		stompClient = over(socket);

		stompClient.connect({}, () => {
			stompClient.subscribe(`/topic/staff/income/${staffId}`, (res) => {
				// console.log('res', res);
				fech_income_data();
			});
		});
	}, []);

	const fetchRange = async (data) => {
		try {
			let response = await API.get(`report/getBillByStaff?type=RANGE&staffId=${staffId}&${data}`);
			if (response.status == 200) {
				let { payload } = response.data;
				payload.totalAmount = payload.totalAmount.toFixed(2);
				setInfoIncome(payload);
				// console.log(payload);
			}
		} catch (error) {
			console.error('fech_income_data', error);
		}
	}



	return (
		<div>
			<div className="income-tab">
				<Nav tabs>
					<NavItem className="income-tab-card">
						<NavLink
							className={classnames({ active: activeTabInCome == '1' })}
							onClick={() => {
								toggle('1');
							}}
						>
							Day
						</NavLink>
					</NavItem>
					<NavItem className="income-tab-card">
						<NavLink
							className={classnames({ active: activeTabInCome == '2' })}
							onClick={() => {
								toggle('2');
							}}
						>
							Week
						</NavLink>
					</NavItem>
					<NavItem className="income-tab-card">
						<NavLink
							className={classnames({ active: activeTabInCome == '3' })}
							onClick={() => {
								toggle('3');
							}}
						>
							Range
						</NavLink>
					</NavItem>
				</Nav>
				{activeTabInCome == '3' ? <div style={{ padding: '16px', paddingTop: 16, paddingBottom: 8 }}>
					<MyDateRangePicker
						showLabel={false}
						className="appointment_range_date_picker"
						title='Appointment range'
						startFieldName='startDate'
						endFieldName='endDate'
						format='YYYY-MM-DD'
						labelFormat='MM-DD-YY'
						stringData={fetchRange}
						startDateDefault={moment().subtract(14, 'day').toDate()}
						endDateDefault={moment().toDate()}
					/>
				</div> : <div style={{ marginBottom: 16 }}></div>}
				<div className="income-total d-flex justify-content-around">
					<div>
						{formatCurrency(sumBy(infoIncome?.tickets || [], o => o.total || 0))}
						<br />
						<span>Total Sales</span>
					</div>
					<div>
						{formatCurrency(infoIncome.commission)}
						<br />
						<span>Commission</span>
					</div>
					<div>
						{formatCurrency((infoIncome.commission || 0) + (infoIncome.tip || 0) - (infoIncome.discount || 0))}
						<br />
						<span>Comm + Tip</span>
					</div>
				</div>
				<div className='income-tip-supply-fee-total'>
					<div className='income-tip-supply-item'>
						<span>Discount</span>
						<span className='value'>
							{formatCurrency(infoIncome.discount)}
						</span>
					</div>
					<div className='income-tip-supply-item'>
						<span>Tip: </span>
						<span className='value'>
							{formatCurrency(infoIncome.tip)}
						</span>
					</div>
					<div className='income-tip-supply-item'>
						<span>Supply Fee:</span>
						<span className='value'>{formatCurrency(sumBy(infoIncome?.tickets || [], o => o.supply || 0))}</span>
					</div>
				</div>
				<hr style={{ margin: '0', marginTop: '16px' }} />

				<div className="income-info d-flex justify-content-center">
					<div style={{ flex: 1, textAlign: 'right' }}>{infoIncome.totalTickets ? infoIncome.totalTickets : 0} tickets</div>
					<span style={{ margin: '0 8px' }}>|</span>
					<div style={{ flex: 1, textAlign: 'left' }}>{infoIncome.totalBooking ? infoIncome.totalBooking : 0} booking</div>
				</div>
			</div>
			<div className="income-history">
				<div className="title mt-3">
					Tickets History
				</div>
				<div className="income-list mt-2">
					{infoIncome.tickets && infoIncome.tickets?.filter(o => o?.total > 0)?.map((ticket) => (
						<div className="income-item" key={ticket.id}>
							<Link to={{
								pathname: "/ticketdetail",
								state: {
									ticketId: ticket.id,
									create_date: ticket.create,
									type: activeTabInCome
								}
							}}
							>
								<div className="info" style={{ position: 'relative' }}>
									<div className="income-id"><p>#{ticket.billNumber}</p></div>
									<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
										<div className="customer-name">Customer: <span>{ticket?.customerName || 'Unknown'}</span></div>
										<div className='ticket-rating' style={{ gap: '8px', justifyContent: 'flex-start' }}>
											<StarRatings
												rating={ticket?.star || 0}
												starRatedColor="#FFC31F"
												numberOfStars={5}
												starDimension={'14px'}
												starSpacing='0px'
												name='rating'
											/>
											<div style={{ flex: 1 }} />
											<div className="create-date tip" style={{ flex: 'unset' }}>Tip: {formatCurrency(ticket?.tip)}</div>
											<div className="create-date tip" style={{ flex: 'unset' }}>Supply Fee: {formatCurrency(ticket?.supply)}</div>
										</div>
									</div>
									<div className="total-price" style={{ position: 'absolute', top: '-4px', right: '0px' }}>{formatCurrency(ticket?.total)}</div>
								</div>
								<div className='ticket-rating'>
									<div className="create-date">{moment(ticket.create, 'MM/DD/YYYY hh:mm:ss A').format('MM-DD-YYYY hh:mm A')}</div>
									<IconArrow />
								</div>
							</Link>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

const IconArrow = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd" d="M8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071Z" fill="#1D2129" />
	</svg>
);
