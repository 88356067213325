import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';

const initialState = {
  pendingGallery: [],
  approvedGallery: [],
  staffPendingGallery: [],
  staffApprovedGallery: [],
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    init: (state) => { },
    staffInit: (state) => { },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.staff.getApprovedGallery.success, (state, { payload }) => {
        state.staffApprovedGallery = payload;
      })
      .addCase(actions.staff.getApprovedGallery.fail, (state) => {
        state.staffApprovedGallery = [];
      })
      .addCase(actions.staff.getPendingGallery.success, (state, { payload }) => {
        state.staffPendingGallery = payload;
      })
      .addCase(actions.staff.getPendingGallery.fail, (state) => {
        state.staffPendingGallery = [];
      })
      .addCase(actions.getApprovedGallery.success, (state, { payload }) => {
        state.approvedGallery = payload;
      })
      .addCase(actions.getApprovedGallery.fail, (state) => {
        state.approvedGallery = [];
      })
      .addCase(actions.getPendingGallery.success, (state, { payload }) => {
        state.pendingGallery = payload;
      })
      .addCase(actions.getPendingGallery.fail, (state) => {
        state.pendingGallery = [];
      })
      ;
  },
});
export const galleryUIActions = Slice.actions;
const galleryServiceReducer = Slice.reducer;
export default galleryServiceReducer;
