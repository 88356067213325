import Page from 'components/Page';
import React, { useEffect, useState } from 'react'
import { Button, Row } from 'reactstrap';
import SalonInfoTabComponent from './Tab';
import PromotionBanner from './PromotionBanner';
import SalonBanner from './SalonBanner';
import SalonContact from './SalonContact';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import shopActions from 'store/shop/actions';
import shopSelectors from 'store/shop/selectors';
import { useForm } from 'react-hook-form';
import { formatPhone } from 'utils/formatPhone';
import { get, set } from 'lodash';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import { API } from 'common';
import { toastRef } from 'components/Toast';
import Loader from 'components/Loader';

const compareChange = (newRecord, defaultRecord) => {
  const record = {};
  Object.entries(newRecord).map(([key, value]) => {
    const oldValue = get(defaultRecord, key);
    if (oldValue === value) return;
    set(record, key, value);
  });
  return record;
}

const SalonInformationPage = () => {
  const dispatch = useAppDispatch();
  const [visibleReview, setVisibleReview] = useState(false);
  const [visibleBannerPromotion, setVisibleBannerPromotion] = useState(false);
  const [visibleContact, setVisibleContact] = useState(false);
  const [banner, setBanner] = React.useState(null);
  const [promotionBanners, setPromotionBanners] = useState([]);
  const shopLoading = useAppSelector(shopSelectors.shopLoading);
  const shopInfo = useAppSelector(shopSelectors.shopInfo);

  const form = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(shopActions.data.shopInfo.fetch());
  }, []);

  useEffect(() => {
    if (!shopInfo) return;
    setBanner({
      id: 'banner-exist',
      src: shopInfo?.bannerUrl || '',
    });
    form.setValue('shopId', shopInfo?.id || '');
    form.setValue('companyName', shopInfo?.companyName || '');
    form.setValue('facebookUrl', shopInfo?.facebookUrl || '');
    form.setValue('instagramUrl', shopInfo?.instagramUrl || '');
    form.setValue('googleUrl', shopInfo?.googleUrl || '');
    form.setValue('yelpUrl', shopInfo?.yelpUrl || '');
    form.setValue('address', shopInfo?.address || '');
    form.setValue('city', shopInfo?.city || '');
    form.setValue('state', shopInfo?.state || '');
    form.setValue('zip', shopInfo?.zip || '');


    setVisibleBannerPromotion(!!shopInfo.showPromotion);

  }, [shopInfo]);

  const onSubmit = async (values) => {
    const body = compareChange(values, {
      facebookUrl: shopInfo?.facebookUrl || '',
      instagramUrl: shopInfo?.instagramUrl || '',
      googleUrl: shopInfo?.googleUrl || '',
      yelpUrl: shopInfo?.yelpUrl || '',
      address: shopInfo?.address || '',
      city: shopInfo?.city || '',
      state: shopInfo?.state || '',
      zip: shopInfo?.zip || '',
    });

    if (banner?.id && banner.id !== 'banner-exist') {
      set(body, 'bannerBase64', removeBase64Prefix(banner.src));
    }

    if (promotionBanners?.length > 0) {
      set(body, 'base64Promotion', promotionBanners.map(o => removeBase64Prefix(o.src)));
    }
    if (values.shopId) {
      set(body, 'shopId', +values.shopId);
    }

    set(body, 'showPromotion', visibleBannerPromotion);
    setLoading(true);
    try {
      const res = await API.put('shop/update', { payload: body });
      console.log(res.data.payload);
      if (res.data.payload) {
        if (toastRef.current) {
          toastRef.current.success('Success');
          dispatch(shopActions.data.shopInfo.fetch());
        }
      }
    } catch (error) {
      if (toastRef.current) toastRef.current.show(error?.response?.data?.message || 'An error occurred, please try again!');
    } finally {
      setLoading(false);
    }
  };

  const StickFooter = () => (
    <Row style={{ padding: '4px 15px', background: '#f0f0f0c4', paddingBottom: '8px' }}>
      <div style={{ margin: '0 15px', width: '100%', display: 'flex', gap: 8 }}>
        <Button onClick={form.handleSubmit(onSubmit)} className="w-100" color="primary" style={{ fontSize: 14, margin: 0, padding: '0.625rem 0rem' }}>Update</Button>
      </div>
    </Row>
  );
  return (
    <>
      {shopLoading || loading && <Loader />}
      <Page StickFooter={StickFooter} title={'Salon Information'} containerClass={'salon-information-content-kit'}>
        <div className="salon-information-content-container">
          <SalonInfoTabComponent title='Salon’s Information' defaultExpand className='salon-information-section'>
            <SalonBanner file={banner} setFile={setBanner} />
            <div className="salon-information-input-container">
              <span className="label-input">Salon’s Name</span>
              <input disabled type="text" value={shopInfo?.companyName || ''} />
            </div>
            <div className="salon-information-input-container">
              <span className="label-input">Email</span>
              <input disabled type="text" value={shopInfo?.email || ''} />
            </div>
            <div className="salon-information-input-container">
              <span className="label-input">Phone</span>
              <input disabled type="text" value={formatPhone(shopInfo?.phone || '')} />
            </div>
          </SalonInfoTabComponent>
          {/* <SalonInfoTabComponent visible={visibleReview} setVisible={setVisibleReview} title='Enable Google Reviews' enableVisible disabledArrow /> */}
          <SalonInfoTabComponent
            className='promotion-banner-section'
            defaultExpand
            visible={visibleBannerPromotion} setVisible={setVisibleBannerPromotion}
            title='Show Banner Promotion'
            enableVisible
          >
            <PromotionBanner data={promotionBanners} setData={setPromotionBanners} />
          </SalonInfoTabComponent>
          <SalonInfoTabComponent defaultExpand className='salon-contact-section' visible={visibleContact} setVisible={setVisibleContact} title='Contact'>
            <SalonContact form={form} />
          </SalonInfoTabComponent>
        </div>
      </Page>
    </>
  )
}

export default SalonInformationPage;
