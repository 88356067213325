import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap';
export const modalViewGalleryRef = React.createRef();
const ModalViewGallery = forwardRef(({ }, ref) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const onClose = () => setOpen(false);
  const toggle = () => setOpen(!open);

  useImperativeHandle(ref, () => ({
    open: (_file) => {
      setOpen(true);
      setFile(_file);
    },
    close: onClose
  }));

  return (
    <Modal modalClassName='gallery-modal' backdropClassName='gallery-modal-backdrop' isOpen={open} centered fade={false} toggle={toggle}>
      <ModalBody>
        <div className="main-media">
          {file && (
            file.type === 'video' ?
              <video src={file.source} autoPlay muted controls /> :
              <img src={file.source} />
          )}
        </div>
      </ModalBody>
      <button className='btn-close' onClick={onClose}><span>Close</span></button>
    </Modal>
  )
})
ModalViewGallery.displayName = 'ModalViewGallery';
export default ModalViewGallery;
