import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { BottomSheet as BottomSheetSpring } from 'react-spring-bottom-sheet'
export const useBottomSheetRef = () => useRef({ open: () => undefined, close: () => undefined });
const BottomSheet = forwardRef(({ children, disabledOk, title = 'Title', header, autoHeight, className = '', onOK = () => undefined, maxHeightRatio = 0.8 }, ref) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    onOK();
  };

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: handleClose,
  }));
  return (
    <BottomSheetSpring
      onDismiss={handleClose}
      open={open}
      className={'bottom_sheet_spring_kit ' + className}
      header={
        <>
          <div className='bottom_sheet_spring_kit__header'>
            <button className='button-btn btn-close' onClick={handleClose}>close</button>
            <p className='title'>{title}</p>
            <button disabled={disabledOk} className='button-btn btn-ok' onClick={handleOk}>OK</button>
          </div>
          {header}
        </>
      }
      snapPoints={autoHeight ? undefined : ({ maxHeight }) => [maxHeight * maxHeightRatio]}
    >
      {children}
    </BottomSheetSpring>
  )
});

export default BottomSheet;
