
const getCurrentState = (state) => state['gallery'];

const gallerySelectors = {
  pendingGallery: (state) => getCurrentState(state).pendingGallery,
  approvedGallery: (state) => getCurrentState(state).approvedGallery,
  staff: {
    pendingGallery: (state) => getCurrentState(state).staffPendingGallery,
    approvedGallery: (state) => getCurrentState(state).staffApprovedGallery,
  }
};

export default gallerySelectors;
