import React, { useState } from 'react';
import arrow_down from '../icons/arrow-down.svg';
import arrow_up from '../icons/arrow-up.svg';
import select_box from '../icons/select-box.svg';
import select_box_checked from '../icons/select-box-checked.svg';
import clsx from 'utils/clsx';

const SalonInfoTabComponent = ({ enableVisible, visible, setVisible, title = '', children, defaultExpand = false, className = '', disabledArrow = false }) => {
  const [open, setOpen] = useState(defaultExpand);
  return (
    <div className="group-section-container">
      <div className="group-section-tab-container" onClick={() => setOpen(!open)}>
        {enableVisible && <button onClick={(e) => [e.stopPropagation(), setVisible(!visible)]} type='button' className='btn-checkbox'>
          <img src={visible ? select_box_checked : select_box} alt='select_box' className='check-box-icon' />
        </button>}
        <div className="section-title">{title}</div>
        {!disabledArrow && <img src={open ? arrow_up : arrow_down} alt="arrow_up" className='group-section-icon' />}
      </div>
      <div className={clsx('group-section-content-container', className, open ? 'visible' : 'hide')}>
        {children}
      </div>
    </div>
  )
}

export default SalonInfoTabComponent;
