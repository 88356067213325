import BottomSheet, { useBottomSheetRef } from 'components/BottomSheet';
import React, { forwardRef, useImperativeHandle, useState } from 'react'
function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const MAX_SIZE_IMAGE = 8000000;
const BSUploadFile = forwardRef(({ onUploadFile = () => undefined }, ref) => {
  const bsRef = useBottomSheetRef();
  const [_staffId, setStaffId] = useState('');
  const [file, setFile] = useState(null);
  const [targetFile, setTargetFile] = useState(null);

  const onChangeFile = (e) => {
    const inputTarget = e.target;
    const _file = inputTarget.files[0];
    setTargetFile(_file);
    setFile(null);

    if (_file) {
      if (!_file.type.includes('video/')) {
        const reader = new FileReader();

        reader.addEventListener("load", function (e) {
          const readerTarget = e.target;
          setFile({ source: readerTarget.result, type: _file.type, name: _file.name, size: _file?.size });
        });
        reader.readAsDataURL(_file);
      } else {
        const videourl = URL.createObjectURL(_file);
        setFile({ source: videourl, type: _file.type, name: _file.name, size: _file?.size });
      }
    }
  }
  const onOK = () => {
    if (bsRef.current) bsRef.current.close();
    onUploadFile(_staffId, targetFile);
  };

  useImperativeHandle(ref, () => ({
    open: (_staffId) => {
      if (bsRef.current) bsRef.current.open();
      setStaffId(_staffId);
      setFile(null);
    },
  }));

  return (
    <BottomSheet
      ref={bsRef}
      autoHeight
      maxHeightRatio={0.50}
      className='filter_option_kit_container_bs bs-upload'
      title={'Upload'}
      onOK={onOK}
      disabledOk={file?.size > MAX_SIZE_IMAGE}
    >
      <div className='filter_option_kit_container_wrap'>
        <div className='filter_option_kit_items'>
          <label className="picture" htmlFor="picture__input" tabIndex="0">
            <span className="picture__image">{file ? (
              file.type.includes('video/') ?
                <video src={file.source} className='picture__img' muted autoPlay /> :
                <img src={file.source} className='picture__img' />
            ) : 'Choose an image'}</span>
          </label>
          <input
            onChange={onChangeFile} type="file" name="picture__input" id="picture__input"
            accept="image/png, image/jpeg, video/*"
          />
          {file && <>
            <div style={{ color: file?.size > MAX_SIZE_IMAGE ? '#f00' : '#1D2129' }}>{file.size > MAX_SIZE_IMAGE ? <span>Exceed size of {file.type.includes('video/') ? 'video' : 'image'}: </span> : <span>Size: </span>} {formatBytes(Math.round(file?.size))}/{Math.round(MAX_SIZE_IMAGE / 1e+6)}MB</div>
          </>}
        </div>
      </div>
    </BottomSheet>
  )
})

export default BSUploadFile;
