import React from 'react';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
const mask = [/\d/, /\d/, '/', /\d/, /\d/];
const hint = 'MM/DD';
const pipe = createAutoCorrectedDatePipe('mm/dd');

const getValue = value => value;

class DOBInput extends React.Component {

  static defaultProps = {};

  getValue = () => {
    if (!this.input) {
      return null;
    }

    return this.input.getValue();
  };

  triggerFocus = () => this.input.FormComponent.triggerFocus();

  render() {
    return (
      <MaskedInput
        mask={mask}
        maskHint={hint}
        pipe={pipe}
        getValue={getValue}
        ref={ref => (this.input = ref)}
        {...this.props}
        placeholder='MM/DD'
      />
    );
  }
}

export default DOBInput;
