import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { customerUIActions } from 'store/customer/reducers';
import customerSelectors from 'store/customer/selectors';
import { useAppDispatch } from 'store/hooks';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';
import CustomerTable from './CustomerTable';
import CustomerPageLayout from './PageLayout';
import customerActions from 'store/customer/actions';
export const CUSTOMER_GROUP_TYPE = {
  LOYALTY: 'LOYALTY',
  AT_RISK: 'NO_VISIT',
  RETURN: 'BEST_RETURN',
  BIRTHDAY: 'BIRTHDAY',
};
const CustomerGroupPage = () => {
  const { group } = useParams();
  const dispatch = useAppDispatch();
  const state = useSelector(customerSelectors.customersByGroup);

  const customers = useMemo(() => {
    return state.data?.map(o => ({
      id: o.customerId,
      customerName: o.customerName,
      phone: o.customerPhone ? `***${o.customerPhone.slice(o.customerPhone.length - 4, o.customerPhone.length)}` : '--',
      createDate: o.createdDate || '--',
      spent: formatCurrency(o.amountSpend),
      point: formatNumber(o.point),
      recentDate: o.lastTimeVisit || '--',
      notReturnedDays: formatNumber(o.numNoReturn) + ' Days',
      returnedDays: formatNumber(o.numReturnDays) + ' Days',
      dob: o.dob || '--',
    }))
  }, [state.data]);

  useEffect(() => {
    dispatch(customerUIActions.initGroup(group));
  }, []);

  const config = useMemo(() => {
    const CUSTOMER_NAME = { key: 'name', title: 'Name', dataIndex: 'customerName', ellipsis: true, className: 'customer width-sm' };
    const CUSTOMER_PHONE = { key: 'phone', title: 'Phone', dataIndex: 'phone' };
    const CREATE_DATE = { key: 'create-date', title: 'Create Date', dataIndex: 'createDate', className: 'create-date' };
    const SPENT = { key: 'spent', title: 'Spent', dataIndex: 'spent', className: 'spent', align: 'right' };
    const POINT = { key: 'point', title: 'Point', dataIndex: 'point', className: 'point', align: 'right' };
    const RECENT_DATE = { key: 'recent-date', title: 'Recent Date', dataIndex: 'recentDate', className: 'recent-date' };
    const NOT_RETURN_DAYS = { key: 'not-return-days', title: "Haven't Returned", dataIndex: 'notReturnedDays', className: 'not-returned-days', align: 'right' };
    const RETURN_DAYS = { key: 'return-days', title: "Returned", dataIndex: 'returnedDays', className: 'returned-days', align: 'right' };
    const DOB = { key: 'dob', title: "DOB", dataIndex: 'dob', className: 'dob' };

    switch (group) {
      case CUSTOMER_GROUP_TYPE.LOYALTY:
        return ({
          label: 'Loyalty Group',
          columns: [CUSTOMER_NAME, CUSTOMER_PHONE, SPENT, POINT],
        });
      case CUSTOMER_GROUP_TYPE.AT_RISK:
        return ({
          label: 'Group At Risk',
          columns: [CUSTOMER_NAME, CUSTOMER_PHONE, RECENT_DATE, NOT_RETURN_DAYS],
        });
      case CUSTOMER_GROUP_TYPE.RETURN:
        return ({
          label: 'Group Returned',
          columns: [CUSTOMER_NAME, CUSTOMER_PHONE, RECENT_DATE, RETURN_DAYS],
        });
      case CUSTOMER_GROUP_TYPE.BIRTHDAY:
        return ({
          label: 'Birthday Coming',
          columns: [CUSTOMER_NAME, CUSTOMER_PHONE, DOB],
        });

      default:
        return ({
          label: 'Group Customer',
          columns: [CUSTOMER_NAME, CUSTOMER_PHONE, CREATE_DATE],
        })
    }
  }, [group]);

  const onPageChange = (page) => {
    dispatch(customerActions.getCustomersByGroup.fetch({ page }));
  };
  const onSearchCustomer = (keySearch) => {
    dispatch(customerActions.getCustomersByGroup.fetch({ keySearch, page: 1 }));
  };
  const onFilter = (query) => {
    dispatch(customerActions.getCustomersByGroup.fetch({ ...query, page: 1 }));
  };

  return (
    <CustomerPageLayout title={config?.label}
      contentClassName='customer-groups-list-page'
      topContent={
        <>
          <div className="total-customer-container">
            <div className="total-customer-text">{formatNumber(state.summary.totalCustomer)}</div>
            <div className="total-customer-label">Customers</div>
          </div>
          <div className="customer-statistics-container">
            <div className="statistics-group">
              <div className="statistics-group-item" style={{ borderRight: '0.5px solid #809BCE' }}>
                <div className="statistics-group-item-label">Spent</div>
                <div className="statistics-group-item-value">{formatCurrency(state.summary.totalSpendAmount)}</div>
              </div>
              <div className="statistics-group-item">
                <div className="statistics-group-item-label">Point</div>
                <div className="statistics-group-item-value">{formatNumber(state.summary.totalPoint)}</div>
              </div>
            </div>
          </div>
        </>
      }
    >
      <CustomerTable
        rowKey={'id'}
        columns={config.columns}
        dataSource={customers}
        total={state.total}
        itemPerPage={state.params.size}
        currentPage={state.params.page}
        onPageChange={onPageChange}
        onSearch={onSearchCustomer}
        onFilter={group !== CUSTOMER_GROUP_TYPE.BIRTHDAY ? onFilter : undefined}
        group={group}
      />
    </CustomerPageLayout>
  )
}

export default CustomerGroupPage;
