import { createAction, createAsyncAction } from 'store/actionConfigs';
import { PREFIX_ACTIONS } from './constants';

const init = createAction(PREFIX_ACTIONS + 'getInitShopData');
const initWithApiKey = createAction(PREFIX_ACTIONS + 'getInitShopDataWithApiKey');
const initDone = createAction(PREFIX_ACTIONS + 'initDone');

const setShopLoading = createAction(PREFIX_ACTIONS + 'shopLoading');

const data = {
  shopInfo: createAsyncAction(PREFIX_ACTIONS + 'shopInfo'),
  staff: createAsyncAction(PREFIX_ACTIONS + 'getStaffs'),
  category: createAsyncAction(PREFIX_ACTIONS + 'category'),
  customer: createAsyncAction(PREFIX_ACTIONS + 'customer'),
  allBanner: createAsyncAction(PREFIX_ACTIONS + 'getAllBanner'),
};

const shopActions = {
  data,
  init,
  initDone,
  initWithApiKey,
  setShopLoading,
};

export default shopActions;

