import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';

const initialState = {
  summary: {
    totalCustomer: 0,
    totalAppointment: 0,
    totalWalkIn: 0,
    totalReturn: 0,
    totalNotReturn: 0,
    totalBirthdayMonthly: 0,
    totalLoyalty: 0,
  },
  customers: [],
  params: {
    page: 1,
    size: 10,
  },
  totalCustomers: 0,
  loadingTable: false,
  customersByGroup: {
    data: [],
    total: 0,
    params: {
      page: 1,
      size: 10,
    },
    summary: {
      totalCustomer: 0,
      totalAppointment: 0,
      totalWalkIn: 0,
      totalReturn: 0,
      totalNotReturn: 0,
      totalBirthdayMonthly: 0,
      totalLoyalty: 0,
    },
  },
  detail: {
    data: null,
    loading: true,
    transactions: [],
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    init: (state) => { },
    initGroup: (state, { payload }) => {
      state.customersByGroup = {
        data: [],
        total: 0,
        params: {
          page: 1,
          size: 10,
          type: payload,
        },
        summary: {
          totalCustomer: 0,
          totalAppointment: 0,
          totalWalkIn: 0,
          totalReturn: 0,
          totalNotReturn: 0,
          totalBirthdayMonthly: 0,
          totalLoyalty: 0,
        },
      }
    },
    initDetail: (state) => {
      state.detail = {
        data: null,
        loading: true,
      }
    },
    setLoadingTable: (state, { payload }) => {
      state.loadingTable = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.getCustomerTransactions.success, (state, { payload }) => {
        state.detail.transactions = payload;
      })
      .addCase(actions.getCustomerTransactions.fail, (state) => {
        state.detail.transactions = [];
      })
      .addCase(actions.getCustomerDetail.success, (state, { payload }) => {
        state.detail.data = payload;
        state.detail.loading = false;
      })
      .addCase(actions.getCustomerDetail.fail, (state) => {
        state.detail.data = null;
        state.detail.loading = false;
      })


      .addCase(actions.getCustomersByGroup.success, (state, { payload }) => {
        state.customersByGroup.data = payload.customers;
        state.customersByGroup.total = payload.total;
      })
      .addCase(actions.getCustomersByGroup.fail, (state) => {
        state.customersByGroup.data = [];
        state.customersByGroup.total = 0;
        state.customersByGroup.params = {
          page: 1,
          size: 10,
        };
      })
      .addCase(actions.getSummaryCustomersByGroup.success, (state, { payload }) => {
        state.customersByGroup.summary = payload;
      })
      .addCase(actions.getSummaryCustomersByGroup.fail, (state) => {
        state.customersByGroup.summary = {
          totalCustomer: 0,
          totalAppointment: 0,
          totalWalkIn: 0,
          totalReturn: 0,
          totalNotReturn: 0,
          totalBirthdayMonthly: 0,
          totalLoyalty: 0,
        };
      })

      .addCase(actions.getCustomersByGroup.fetch, (state, { payload }) => {
        const _params = { ...state.customersByGroup.params, ...payload };
        if (_params.keySearch == '' || _params.keySearch == undefined) {
          delete _params.keySearch;
        }
        state.customersByGroup.params = _params;
        if (state.customersByGroup.params.page === 1) {
          state.customersByGroup.customers = [];
        }
      })

      .addCase(actions.getAllCustomers.success, (state, { payload }) => {
        state.customers = payload.customers;
        state.totalCustomers = payload.total;
      })
      .addCase(actions.getAllCustomers.fail, (state) => {
        state.customers = [];
        state.totalCustomers = 0;
      })
      .addCase(actions.getAllCustomers.fetch, (state, { payload }) => {
        const _params = { ...state.params, ...payload };
        if (_params.keySearch == '' || _params.keySearch == undefined) {
          delete _params.keySearch;
        }
        state.params = _params;
        if (state.params.page === 1) {
          state.customers = [];
        }
      })

      .addCase(actions.getSummaryCustomers.success, (state, { payload }) => {
        state.summary = payload;
      })
      .addCase(actions.getSummaryCustomers.fail, (state) => {
        state.summary = {
          totalCustomer: 0,
          totalAppointment: 0,
          totalWalkIn: 0,
          totalReturn: 0,
          totalNotReturn: 0,
          totalBirthdayMonthly: 0,
          totalLoyalty: 0,
        };
      })
  },
});
export const customerUIActions = Slice.actions;
const customerServiceReducer = Slice.reducer;
export default customerServiceReducer;
