import moment from 'moment';
import React from 'react';
import StarRatings from 'react-star-ratings';
import customerSelectors from 'store/customer/selectors';
import { useAppSelector } from 'store/hooks';
import { formatCurrency } from 'utils/formatCurrency';
import cashIconSvg from './icons/cash.svg';
import creditCardIconSvg from './icons/credit-card.svg';
import loyaltyIconSvg from './icons/loyalty.svg';

const loyaltyIcon = <img src={loyaltyIconSvg} alt="loyaltyIconSvg" className="icon-payment" />
const cashIcon = <img src={cashIconSvg} alt="cashIconSvg" className="icon-payment" />
const creditCardIcon = <img src={creditCardIconSvg} alt="creditCardIconSvg" className="icon-payment" />
const PAYMENT_TYPE = {
  CREDIT_CARD: 'CREDIT_CARD',
  CASH: 'CASH',
  CHECK: 'CHECK',
  GIFT_CARD: 'GIFT_CARD',
  POINT: 'POINT',
  OTHERS: 'OTHERS',
}
const FORMAT_DATE = 'MM-DD-YYYY';

const HistoryTab = () => {
  const transactions = useAppSelector(customerSelectors.customerTransactions);
  return (
    <div className='customer-detail-tab-content-history-tab'>
      <div className="tickets-container">
        <div className="ticket-label">Tickets</div>
        <div className="tickets-list">
          {transactions?.map((o, i) => (
            <div key={i} className="ticket-item">
              <div className="ticket-top-container">
                <div className="ticket-number">
                  <span>#{o.ticketNumber}</span>
                </div>
                <div className="staff-time-container">
                  <div className="staff"><span>Staff: </span><span>{o.staffNames?.join(', ')}</span></div>
                  <span className="time">{moment(o?.createdDate, 'MM-DD-YYYY HH:mm').format('MM-DD-YYYY hh:mm A')}</span>
                </div>
                <StarRatings
                  rating={o.stars}
                  starRatedColor="#FFC31F"
                  numberOfStars={5}
                  starDimension={'20px'}
                  starSpacing='0px'
                  name='rating'
                />
              </div>
              <div className="ticket-payment">
                <div className="total-text">TOTAL: {formatCurrency(o.total)}</div>
                <div className="ticket-payment-types">
                  {
                    o?.payments?.map(p => {
                      if (p.paymentType === PAYMENT_TYPE.CREDIT_CARD) return (
                        <div key={PAYMENT_TYPE.CREDIT_CARD} className="ticket-payment-type-card-container">
                          {creditCardIcon}
                          <span>{p.last4Digit || '--'}</span>
                        </div>
                      )
                      if (p.paymentType === PAYMENT_TYPE.POINT) return <React.Fragment key={PAYMENT_TYPE.POINT}>{loyaltyIcon}</React.Fragment>;
                      if (p.paymentType === PAYMENT_TYPE.CASH) return <React.Fragment key={PAYMENT_TYPE.CASH}>{cashIcon}</React.Fragment>;
                      return null;
                    })}
                </div>
              </div>
              <div className="service-ticket-info">
                <div className="service-text">Services: {o?.serviceNames?.join(', ')}</div>
                <div className="ticket-info-group">
                  <div className="ticket-info-group-item">
                    <div className="ticket-info-content-item">
                      <span>Supply:</span>
                      <span>{formatCurrency(o.supply)}</span>
                    </div>
                    <div className="ticket-info-content-item">
                      <span>Tip:</span>
                      <span>{formatCurrency(o.tip)}</span>
                    </div>
                  </div>
                  <div className="ticket-info-group-item">
                    <div className="ticket-info-content-item">
                      <span>Discount:</span>
                      <span>{formatCurrency(o.discount)}</span>
                    </div>
                    <div className="ticket-info-content-item">
                      <span>Tax:</span>
                      <span>{formatCurrency(o.taxes)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HistoryTab;
