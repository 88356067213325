import moment from 'moment';
import React, { useState } from 'react'
import DatePicker from "react-datepicker";

const MyDateRangePicker = ({
  stringData, title = "Range Picker", rightButton, className = '',
  startFieldName = 'startDate',
  endFieldName = 'endDate',
  format = 'MM-DD-YYYY',
  labelFormat = 'MM-DD-YYYY',
  showLabel = true,
  startDateDefault,
  endDateDefault,
}) => {
  const calendarRef = React.createRef();
  const [startDate, setStartDate] = useState(startDateDefault ?? new Date());
  const [endDate, setEndDate] = useState(endDateDefault ?? new Date());

  return (
    <div className={`date_picker_kit ${className}`}>
      {showLabel && <div className="date_picker_kit_report_date" style={{ marginBottom: '10px' }}><span>{title}</span> {rightButton}</div>}
      <div className="date_picker_kit_from_to" style={{ marginBottom: '8px', gap: '8px' }}>
        <DatePicker
          ref={calendarRef}
          selected={startDate}
          onChange={async (date) => {
            setStartDate(date);
            const start_date = moment(date).format(format);
            const end_date = moment(endDate).format(format)
            const range_param = `${startFieldName}=${start_date}&${endFieldName}=${end_date}`;
            await stringData(range_param);
          }}
          selectsStart
          withPortal
          customInput={
            <div className='date_picker_kit_report_date range' onClick={() => { calendarRef.current.setOpen(true) }}>
              From: {moment(startDate).format(labelFormat)}
              <CalendarIcon />
            </div>
          }
        />
        <DatePicker
          ref={calendarRef}
          selected={endDate}
          onChange={async (date) => {
            setEndDate(date);
            const start_date = moment(startDate).format(format);
            const end_date = moment(date).format(format)
            const range_param = `${startFieldName}=${start_date}&${endFieldName}=${end_date}`;
            await stringData(range_param);
          }}
          selectsEnd
          minDate={startDate}
          withPortal
          customInput={
            <div className='date_picker_kit_report_date range' onClick={() => { calendarRef.current.setOpen(true) }}>
              To: {moment(endDate).format(labelFormat)}
              <CalendarIcon />
            </div>
          }
        />
      </div>
    </div>
  )
}

export default MyDateRangePicker;


const CalendarIcon = () => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="calendar">
      <path id="Vector" d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_2" d="M14 1V5" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_3" d="M6 1V5" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_4" d="M1 9H19" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);
