import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { customerUIActions } from 'store/customer/reducers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatNumber } from 'utils/formatCurrency';
import { CUSTOMER_GROUP_TYPE } from './CustomerGroupPage';
import CustomerTable from './CustomerTable';
import CustomerPageLayout from './PageLayout';
import customerSelectors from 'store/customer/selectors';
import { formatPhone } from 'utils/formatPhone';
import customerActions from 'store/customer/actions';

const columns = [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'customerName',
    ellipsis: true,
    className: 'customer',
  },
  {
    key: 'phone',
    title: 'Phone',
    dataIndex: 'phone',
    className: 'phone',
  },
  {
    key: 'create-date',
    title: 'Create Date',
    dataIndex: 'createDate',
    className: 'create-date',
  },
];

const CustomerReport = () => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(customerSelectors.params);
  const _customers = useAppSelector(customerSelectors.customers);
  const totalCustomers = useAppSelector(customerSelectors.totalCustomers);

  const customers = useMemo(() => {
    return _customers.map(o => ({
      id: o.customerId,
      customerName: o.customerName,
      phone: formatPhone(o.customerPhone),
      createDate: o.createdDate,
    }));
  }, [_customers]);

  useEffect(() => {
    dispatch(customerUIActions.init());
  }, []);
  const onPageChange = (page) => {
    dispatch(customerActions.getAllCustomers.fetch({ page }));
  };
  const onSearchCustomer = (keySearch) => {
    dispatch(customerActions.getAllCustomers.fetch({ keySearch, page: 1 }));
  };

  return (
    <CustomerPageLayout
      contentClassName='customer-groups-list-page'
      topContent={<CustomerTopContent />}
    >
      <CustomerTable
        rowKey={'id'}
        columns={columns}
        dataSource={customers}
        total={totalCustomers}
        itemPerPage={10}
        currentPage={params.page}
        onPageChange={onPageChange}
        onSearch={onSearchCustomer}
      />
    </CustomerPageLayout>
  )
}

export default CustomerReport;

const CustomerTopContent = () => {
  const _history = useHistory();
  const summary = useAppSelector(customerSelectors.summary);

  const onNavToGroup = (group = '') => () => {
    _history.push(`/customer-group/${group}`);
  };

  return (
    <>
      <div className="total-customer-container">
        <div className="total-customer-text">{formatNumber(summary?.totalCustomer || 0)}</div>
        <div className="total-customer-label">Customers</div>
      </div>
      <div className="customer-statistics-container">
        <div className="statistics-group">
          <div className="statistics-group-item" style={{ borderRight: '0.5px solid #809BCE' }}>
            <div className="statistics-group-item-label">Appointment</div>
            <div className="statistics-group-item-value">{formatNumber(summary?.totalAppointment || 0)}</div>
          </div>
          <div className="statistics-group-item">
            <div className="statistics-group-item-label">Walk-In</div>
            <div className="statistics-group-item-value">{formatNumber(summary?.totalWalkIn || 0)}</div>
          </div>
        </div>
        <div className="customer-group">
          <button type='button' className="customer-group-item">
            <div className="customer-group-item-label" onClick={onNavToGroup(CUSTOMER_GROUP_TYPE.LOYALTY)}><div>Loyalty</div> <div>group</div></div>
            <div className="customer-group-item-value">{formatNumber(summary?.totalLoyalty || 0)}</div>
          </button>
          <button type='button' className="customer-group-item" onClick={onNavToGroup(CUSTOMER_GROUP_TYPE.AT_RISK)}>
            <div className="customer-group-item-label"><div>Group at</div> <div>risk</div></div>
            <div className="customer-group-item-value">{formatNumber(summary?.totalNotReturn || 0)}</div>
          </button>
          <button type='button' className="customer-group-item" onClick={onNavToGroup(CUSTOMER_GROUP_TYPE.RETURN)}>
            <div className="customer-group-item-label"><div>Group</div><div>return</div></div>
            <div className="customer-group-item-value">{formatNumber(summary?.totalReturn || 0)}</div>
          </button>
          <button type='button' className="customer-group-item" onClick={onNavToGroup(CUSTOMER_GROUP_TYPE.BIRTHDAY)}>
            <div className="customer-group-item-label"><div>Birthday</div> <div>coming</div></div>
            <div className="customer-group-item-value">{formatNumber(summary?.totalBirthdayMonthly || 0)}</div>
          </button>
        </div>
      </div>
    </>
  )
}
