import React from 'react'
import image_icon from '../icons/image.svg';
import clsx from 'utils/clsx';
import edit_icon from '../icons/edit.svg';
import delete_icon from '../icons/delete.svg';

const UploadContainer = ({ id, file, onDelete = () => undefined, onAdd = () => undefined, onChange = () => undefined }) => {
  const isExist = id !== 'add_promotion_banner_upload';
  const onChangeFile = (e) => {
    const inputTarget = e.target;
    const _file = inputTarget.files[0];
    if (_file) {
      const reader = new FileReader();
      reader.addEventListener("load", function (e) {
        const readerTarget = e.target;
        if (!isExist)
          onAdd({ src: readerTarget.result, type: _file.type, name: _file.name });
        else
          onChange(id, { src: readerTarget.result, type: _file.type, name: _file.name });
      });
      reader.readAsDataURL(_file);
    }
  }

  if (isExist) {
    return (
      <div className={clsx('upload-banner-container', 'isExist')}>
        <div className='picture-container'>
          {file ? <span className="picture__image">
            <img src={file.src} className='picture__img' />
          </span> : <>
            <img src={image_icon} alt="image_icon" style={{ width: '32px', height: '32px' }} />
            <span className='upload-label'>Add New Banner</span>
          </>}
          <label className="btn-edit" htmlFor={id} tabIndex="0">
            <img src={edit_icon} alt="edit_icon" className='edit_icon' />
          </label>
          <button type='button' onClick={e => [e.stopPropagation(), onDelete(id)]} className="btn-delete">
            <img src={delete_icon} alt="delete_icon" className='delete_icon' />
          </button>
        </div>
        <input
          onChange={onChangeFile} type="file" name="picture__input" id={id}
          className='input-handler'
          accept="image/png, image/jpeg"
        />
      </div>
    )
  }

  return (
    <div className={'upload-banner-container'}>
      <label className="picture-container" htmlFor={id} tabIndex="0">
        {file ? <span className="picture__image">
          <img src={file.src} className='picture__img' />
        </span> : <>
          <img src={image_icon} alt="image_icon" style={{ width: '32px', height: '32px' }} />
          <span className='upload-label'>Add New Banner</span>
        </>}

      </label>
      <input
        onChange={onChangeFile} type="file" name="picture__input" id={id}
        className='input-handler'
        accept="image/png, image/jpeg"
      />
    </div>
  )
}

export default UploadContainer;
