import Loader from 'components/Loader';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { customerUIActions } from 'store/customer/reducers';
import customerSelectors from 'store/customer/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import clsx from 'utils/clsx';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';
import CustomerPageLayout from '../PageLayout';
import AboutTab from './AboutTab';
import HistoryTab from './HistoryTab';
const TAB_TYPE = {
  ABOUT: 'ABOUT',
  HISTORY: 'HISTORY',
};
const CustomerDetailPage = () => {
  const dispatch = useAppDispatch();
  const _history = useHistory();
  const { id = '' } = useParams();
  const [tab, setTab] = React.useState(TAB_TYPE.ABOUT);
  const state = useAppSelector(customerSelectors.detail);

  useEffect(() => {
    dispatch(customerUIActions.initDetail(id));
  }, []);

  const onEdit = () => _history.push('/customer/edit/' + id);

  if (state.loading) return <Loader />;
  if (!state.data) return <Page title={'Not found'}><p>404 Not Found</p></Page>;

  return (
    <CustomerPageLayout
      title={state.data.customerName} onEdit={onEdit}
      contentClassName='customer-detail-page'
      topContent={
        <>
          <div className="customer-statistics-container">
            <div className="statistics-group">
              <div className="statistics-group-item" style={{ borderRight: '0.5px solid #809BCE' }}>
                <div className="statistics-group-item-label">Amount Spent</div>
                <div className="statistics-group-item-value">{formatCurrency(state.data.amountSpend)}</div>
              </div>
              <div className="statistics-group-item">
                <div className="statistics-group-item-label">Point</div>
                <div className="statistics-group-item-value">{formatNumber(state.data.point)}</div>
              </div>
            </div>
          </div>
          <div className="customer-statistics-container">
            <div className="statistics-group">
              <div className="statistics-group-item" style={{ borderRight: '0.5px solid #809BCE' }}>
                <div className="statistics-group-item-label">Walk-In</div>
                <div className="statistics-group-item-value">{formatNumber(state.data.totalWalkIn)}</div>
              </div>
              <div className="statistics-group-item">
                <div className="statistics-group-item-label">Appointment</div>
                <div className="statistics-group-item-value">{formatNumber(state.data.totalAppointment)}</div>
              </div>
            </div>
          </div>
        </>
      }
    >
      <div className="customer-detail-tabs">
        <button onClick={() => setTab(TAB_TYPE.ABOUT)} className={clsx("customer-detail-tab-item", tab === TAB_TYPE.ABOUT && 'active')}>
          <div className="customer-detail-tab-item-label">About</div>
        </button>
        <button onClick={() => setTab(TAB_TYPE.HISTORY)} className={clsx("customer-detail-tab-item", tab === TAB_TYPE.HISTORY && 'active')}>
          <div className="customer-detail-tab-item-label">History</div>
        </button>
      </div>

      <div className={clsx("customer-detail-tab-content", tab === TAB_TYPE.ABOUT ? 'show' : 'hidden')}><AboutTab /></div>
      <div className={clsx("customer-detail-tab-content", tab === TAB_TYPE.HISTORY ? 'show' : 'hidden')}><HistoryTab /></div>

    </CustomerPageLayout>
  )
}

export default CustomerDetailPage;
