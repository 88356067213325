import React from 'react'
import backIcon from './icons/back.svg';
import editIcon from './icons/edit.svg';
import { useHistory } from 'react-router-dom';

const CustomerPageLayout = ({ children, title = 'Customers', onEdit, topContent, contentClassName = '' }) => {
  const _history = useHistory();
  return (
    <div className="customer-report-ui-kit">
      <div className="header-container">
        <div className="top-bar">
          <button onClick={() => _history?.goBack()} className="btn-action btn-left"><img src={backIcon} alt="backIcon" /></button>
          <div className="top-bar-title">{title}</div>
          {onEdit ? <button onClick={onEdit} className="btn-action btn-right"><img src={editIcon} alt="editIcon" /></button> : <button className='btn-action' ><div style={{ width: 24, height: 24 }}></div></button>}
        </div>
        <div className="top-content-container">
          {topContent}
        </div>
      </div>
      <div className={`customer-report-ui-kit__content-container ${contentClassName}`}>
        {children}
      </div>
    </div>
  )
}

export default CustomerPageLayout;
