import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { API } from "common";
import { customerUIActions } from "./reducers";
import customerSelectors from "./selectors";

const getAllCustomers = function* ({ payload }) {
  const storeParams = yield select(customerSelectors.params);
  const params = { ...storeParams, ...payload };
  if (params.keySearch == '' || params.keySearch == undefined) {
    delete params.keySearch;
  }
  yield put(customerUIActions.setLoadingTable(true));
  try {
    const res = yield call(API.post, `/customer/filterByGroup`, params);
    if (res.data.payload) {
      yield put(actions.getAllCustomers.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getAllCustomers.fail({}));
  } finally {
    yield put(customerUIActions.setLoadingTable(false));
  }
}

const getSummaryCustomers = function* () {
  try {
    const res = yield call(API.get, '/customer/summary');
    if (res.data.payload) {
      yield put(actions.getSummaryCustomers.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getSummaryCustomers.fail({}));
  }
}

const getCustomersByGroup = function* ({ payload }) {
  const storeParams = yield select(customerSelectors.customersByGroup);
  const params = { ...storeParams.params, ...payload };
  if (params.keySearch == '' || params.keySearch == undefined) {
    delete params.keySearch;
  }

  yield put(customerUIActions.setLoadingTable(true));
  try {
    const res = yield call(API.post, `/customer/filterByGroup`, params);
    if (res.data.payload) {
      yield put(actions.getCustomersByGroup.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getCustomersByGroup.fail({}));
  } finally {
    yield put(customerUIActions.setLoadingTable(false));
  }
}
const getSummaryCustomersByGroup = function* ({ payload }) {
  try {
    const res = yield call(API.get, '/customer/summary?groupType=' + payload);
    if (res.data.payload) {
      yield put(actions.getSummaryCustomersByGroup.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getSummaryCustomersByGroup.fail({}));
  }
}

const getCustomerDetail = function* ({ payload: customerId }) {
  try {
    const res = yield call(API.get, `/customer/detail/${customerId}`);
    if (res.data.payload) {
      yield put(actions.getCustomerDetail.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getCustomerDetail.fail({}));
  }
}
const getCustomerTransactions = function* ({ payload: customerId }) {
  try {
    const res = yield call(API.get, `/customer/transactions/${customerId}`);
    if (res.data.payload) {
      yield put(actions.getCustomerTransactions.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getCustomerTransactions.fail({}));
  }
}

const customerInit = function* () {
  yield delay(200);
  yield all([
    getAllCustomers({ payload: {} }),
    getSummaryCustomers(),
  ])
}
const customerGroupInit = function* ({ payload }) {
  yield delay(200);
  yield all([
    put(actions.getCustomersByGroup.fetch({ type: payload })),
    put(actions.getSummaryCustomersByGroup.fetch(payload)),
  ])
}

const customerInitDetail = function* ({ payload }) {
  yield delay(200);
  yield all([
    put(actions.getCustomerDetail.fetch(payload)),
    put(actions.getCustomerTransactions.fetch(payload)),
  ]);
}

export default function* customerServiceSagas() {
  yield takeLatest(customerUIActions.init, customerInit);
  yield takeLatest(customerUIActions.initGroup, customerGroupInit);
  yield takeLatest(customerUIActions.initDetail, customerInitDetail);
  yield takeLatest(actions.getAllCustomers.fetch, getAllCustomers);
  yield takeLatest(actions.getSummaryCustomers.fetch, getSummaryCustomers);
  yield takeLatest(actions.getCustomersByGroup.fetch, getCustomersByGroup);
  yield takeLatest(actions.getSummaryCustomersByGroup.fetch, getSummaryCustomersByGroup);
  yield takeLatest(actions.getCustomerDetail.fetch, getCustomerDetail);
  yield takeLatest(actions.getCustomerTransactions.fetch, getCustomerTransactions);
}
