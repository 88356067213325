import Page from 'components/Page';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'utils/clsx';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import shopSelectors from 'store/shop/selectors';
import StaffMediaItem from './Item';
import { galleryUIActions } from 'store/gallery/reducers';
import ModalViewGallery, { modalViewGalleryRef } from '../ModalViewGallery';
import BSUploadFile from '../BSUploadFile';
import Loader from 'components/Loader';
import { API } from 'common';
import gallerySelectors from 'store/gallery/selectors';
import galleryActions from 'store/gallery/actions';
 
const ImageReviewPage = () => {
  const modalUploadRef = useRef(null);
  const dispatch = useAppDispatch();
  const _staffs = useAppSelector(shopSelectors.staff);
  const [tab, setTab] = useState('NOT_APPROVED');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(galleryUIActions.init());
  }, []);

  const onUploadFile = (_staffId) => {
    if (modalUploadRef.current) modalUploadRef.current.open(_staffId);
  }
  const handleUploadFile = async (_staffId, file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const res = await API.post(`/gallery/upload/${_staffId}`, formData);
      if (res.data.payload) {
        dispatch(galleryActions.getPendingGallery.fetch());
        setTab('NOT_APPROVED');
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }

  const onDeleteFile = (pending) => async (selectedIds) => {
    setLoading(true);
    try {
      const res = await API.post(`/gallery/delete`, selectedIds);
      if (res.data.payload) {
        if (pending) {
          dispatch(galleryActions.getPendingGallery.fetch());
          setTab('NOT_APPROVED');
        } else {
          dispatch(galleryActions.getApprovedGallery.fetch());
        }
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  }
  const onPublishFile = async (selectedIds) => {
    setLoading(true);
    try {
      const res = await API.post(`/gallery/approved`, selectedIds);
      if (res.data.payload) {
        setTab('PUBLIC');
        dispatch(galleryActions.getApprovedGallery.fetch());
        dispatch(galleryActions.getPendingGallery.fetch());
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Page
        title={'Images'}
        containerClass={'image-gallery-content-kit'}
        className={'image-gallery-content-wrapper'}
        Sticky={() =>
          <div className="tab-container">
            <button onClick={() => setTab('NOT_APPROVED')} className={clsx("tab-item", tab === 'NOT_APPROVED' && 'active')}>
              <span>Not approved</span>
            </button>
            <button onClick={() => setTab('PUBLIC')} className={clsx("tab-item", tab === 'PUBLIC' && 'active')}>
              <span>Public</span>
            </button>
          </div>
        }
      >

        <div className="staff-list-container" style={{ display: tab === 'PUBLIC' ? 'none' : 'flex' }}>
          <PendingTab _staffs={_staffs} onPublish={onPublishFile} onUploadFile={onUploadFile} onDelete={onDeleteFile(true)} />
        </div>
        <div className="staff-list-container" style={{ display: tab !== 'PUBLIC' ? 'none' : 'flex' }}>
          <PublicTab _staffs={_staffs} onPublish={onPublishFile} onUploadFile={onUploadFile} onDelete={onDeleteFile(false)} />
        </div>
      </Page>
      <ModalViewGallery ref={modalViewGalleryRef} />
      <BSUploadFile ref={modalUploadRef} onUploadFile={handleUploadFile} />
    </>
  )
}

export default ImageReviewPage;

function checkURL(url) {
  return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

const PendingTab = ({ onUploadFile, _staffs, onDelete, onPublish }) => {
  const list = useAppSelector(gallerySelectors.pendingGallery);
  const staffImages = useMemo(() => {
    return list.map(o => {
      const galleries = o?.details || [];
      return ({
        id: o.staffId?.toString(),
        name: o.staffName,
        avatar: o.avatar,
        files: galleries?.map(s => ({
          type: checkURL(s.urlImage) ? 'image' : 'video',
          id: s.galleryId,
          source: s.urlImage,
        })),
      });
    });
  }, [_staffs, list]);

  return <>
    {staffImages.map(o => (
      <StaffMediaItem onPublish={onPublish} onDelete={onDelete} key={o.id} onUpload={onUploadFile} data={o} pending />
    ))}
  </>
};
const PublicTab = ({ onUploadFile, _staffs, onDelete, onPublish }) => {
  const list = useAppSelector(gallerySelectors.approvedGallery);
  const staffImages = useMemo(() => {
    return _staffs.map(o => {
      const galleries = list.find(s => s.staffId?.toString() === o.id?.toString())?.details || [];
      return ({
        id: o.id,
        name: o.name,
        avatar: o.avatar,
        files: galleries?.map(s => ({
          type: checkURL(s.urlImage) ? 'image' : 'video',
          id: s.galleryId,
          source: s.urlImage,
        })),
      });
    })
  }, [_staffs, list]);

  return <>
    {staffImages.map(o => (
      <StaffMediaItem onPublish={onPublish} onDelete={onDelete} key={o.id} onUpload={onUploadFile} data={o} pending={false} />
    ))}
  </>
};
